import React, {FC, useEffect } from "react";
import {IEntityFieldProps} from "icerockdev-admin-toolkit";
import {EntityFieldReferenceSelect} from "~/common/components/EntityFieldReferenceSelect";
import queryString from "query-string";

const ConstructionTypeIdReferenceSelect: FC<IEntityFieldProps> = ({name, value, options, data, ...props}) => {
    const initValue = value ?? queryString.parse(window.location.search)['constructionTypeId'] ?? '';
    // TODO replace with correct code
    useEffect(() => {
        Object.assign(data, {[name]: initValue});
    }, [value, initValue]);
    return (
        <EntityFieldReferenceSelect
            {...props}
            name={name}
            isEditing={false}
            value={initValue}
            data={data}
        />
    );
};

export default ConstructionTypeIdReferenceSelect;
