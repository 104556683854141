import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityUpdateFunction,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';
import { uploadFile } from '~/utils/api';

export const createItemsFn = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const imageKey = data.image ? await uploadFile({ file: data.image, token }) : '';
  const sashList = data.sashList && (await Promise.all(data.sashList.map(async (sash) => {
    const imageKey = sash.image ? await uploadFile({ file: sash.image, token }) : '';
    Object.assign(sash, {imageKey: imageKey || sash.imageKey});
    return sash
  })));
  const request = { ...data, imageKey: imageKey || data.imageKey, sashList: sashList };

  const result = await axios.post(url, request, {
    headers: { authorization: token ?? '' },
  });

  return { data: result.data.data };
};

export const updateItemsFn: IEntityUpdateFunction = async ({ url, token, data }) => {
  const imageKey = data.image ? await uploadFile({ file: data.image, token }) : '';
  const sashList = data.sashList && (await Promise.all(data.sashList.map(async (sash) => {
    const imageKey = sash.image ? await uploadFile({ file: sash.image, token }) : '';
    Object.assign(sash, {imageKey: imageKey || sash.imageKey});
    return sash
  })));
  const request = { ...data, imageKey: imageKey || data.imageKey, sashList: sashList };

  const result = await axios.put(`${url}/${data.id}`, request, {
    headers: { authorization: token ?? '' },
  });

  return {
    data: result.data,
  };
};
