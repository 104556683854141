import {
  IEntityFetchFunction,
  IEntityUpdateFunction,
  IEntityGetFunction,
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  Entity,
} from 'icerockdev-admin-toolkit';
import qs from 'query-string';
import { API_URLS, REFERENCE_LIMIT } from '~/utils/constants';
import { jwtExpired } from '~/utils/catcher';

import { axios } from '~/utils/axios';

type IUploadFileFn = ({ file: File, token: string }) => Promise<string> | undefined;

export const uploadFile: IUploadFileFn = async ({ file, token }) => {
  if (!file || typeof file !== 'object') return file;

  const formData = new FormData();
  formData.append('file', file);

  const response = await axios
    .post(API_URLS.upload, formData, {
      headers: { 'Content-Type': 'multipart/form-data', authorization: token },
    })
    .catch(jwtExpired);

  return response.data.data;
};

export const fetchItemsFn: IEntityFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy,
  filter,
}) => {
  const offset = page && count ? page * count : 0;
  const limit = count ? (page + 1) * count : 25;
  const orderBy = sortDir?.toUpperCase();
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}) || {};

  const response = await axios.get(url, {
    params: {
      offset,
      limit,
      ...(sortBy ? { sortBy, orderBy } : {}),
      ...filters,
    },
    headers: { authorization: token ?? '' },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
  });

  const list = response?.data?.data || [];

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || '0', 10),
    },
    filterData: (list && list[0]) || {},
    error: '',
  };
};

export const updateItemsFn: IEntityUpdateFunction = async ({ url, token, data }) => {
  const result = await axios.put(
    `${url}/${data.id}`,
    { ...data, name: data.name.trim() },
    {
      headers: { authorization: token ?? '' },
    }
  );

  return {
    data: result.data,
  };
};

export const getItemsFn: IEntityGetFunction = async ({ url, token, id }) => {
  const result = await axios.get(`${url}/${id}`, {
    headers: {
      authorization: token ?? '',
    },
  });

  return {
    data: result.data.data,
    error: '',
  };
};

export const deleteItemsFn: IEntityGetFunction = async ({ url, token }) => {
  const result = await axios.delete(`${url}`, {
    headers: { authorization: token ?? '' },
  });

  return {
    data: result.data,
  };
};

export const blockItemsFn = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.post(
    `${url}/block`,
    {},
    {
      headers: { authorization: token ?? '' },
    }
  );

  return {
    data: result.data,
  };
};

export const unblockItemsFn = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.post(
    `${url}/unblock`,
    {},
    {
      headers: { authorization: token ?? '' },
    }
  );

  return {
    data: result.data,
  };
};

export const createItemsFn = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.post(
    url,
    { ...data, name: data.name.trim() },
    {
      headers: { authorization: token ?? '' },
    }
  );

  return { data: result.data.data };
};

declare type GetManyReferenceFunctionProps = {
  url: string;
  entity: Entity;
  params?: Record<string, string>;
};

export const getManyReference = async ({
  url,
  entity,
  params = {},
}: GetManyReferenceFunctionProps): Promise<Record<string, any>> => {
  const response = await entity?.parent?.auth?.withToken(
    ({ token }) =>
      axios.get(url, {
        params: { limit: REFERENCE_LIMIT, ...params },
        headers: { authorization: token ?? '' },
      }),
    {}
  );

  if (!response?.data?.data) return {};

  return response.data.data.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
};
