/* eslint-disable no-shadow */

export const ONLY_CYRILLIC_SYMBOLS = /^([а-яА-Я ]{0,500})$/;
export const REFERENCE_LIMIT = 9999;
export const API_URLS = {
  auth: {
    signin: '/v1/auth/signin',
    refresh: '/v1/auth/refresh',
    logout: '/v1/auth/logout',
    restore: '/v1/auth/restore',
  },
  user: {
    profile: '/v1/admin/profile',
    user: '/v1/admin',
  },
  colorSolution: '/v1/color-solutions',
  fittingSolution: '/v1/fitting-solutions',
  sections: '/v1/sections',
  calculators: {
    constructionTypes: '/v1/construction/type',
    construction: '/v1/construction',
  },
  solutionCategory: '/v1/solution-categories',
  solutions: '/v1/solutions',
  upload: '/v1/file/upload',
  faqs: '/v1/faqs',
  userb2c: '/v1/user',
  constructionType: '/v1/construction/type',
  construction: '/v1/construction',
  constructionHouseType: '/v1/construction/house/type',
  constructionDoubleGlazing: '/v1/construction/double-glazing',
  constructionSashList: '/v1/construction/sash'
};
export const DEFAULT_MIN_STRING_LENGTH = 1;
export const DEFAULT_MAX_STRING_LENGTH = 40;

export enum DefaultStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export const DEFAULT_STATUS_NAMES = {
  [DefaultStatus.Active]: 'Активен',
  [DefaultStatus.Blocked]: 'Заблокирован',
};
