import {API_URLS, DefaultStatus} from '~/utils/constants';
import {fetchItemsFn, getItemsFn, getManyReference} from '~/utils/api';
import ConstructionEntity from './components/ConstructionEntity';
import { createItemsFn, updateItemsFn } from '~/pages/construction/api';
import { CONSTRUCTION_FIELDS } from "~/pages/construction/fields";
import { Entity } from "icerockdev-admin-toolkit";

const CONSTRUCTION_URL = API_URLS.construction;
const TITLE = 'Конструкция';

const Construction = new ConstructionEntity({
  title: TITLE,
  viewable: false,
  editable: true,
  creatable: true,
  menu: {
    label: TITLE,
    url: '/construction',
    enabled: false,
  },
  api: {
    list: { url: CONSTRUCTION_URL, method: 'get' },
    get: { url: CONSTRUCTION_URL, method: 'get' },
    update: { url: CONSTRUCTION_URL, method: 'put' },
    create: { url: CONSTRUCTION_URL, method: 'post' },
  },
  fields: CONSTRUCTION_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  updateItemsFn,
  references: {
    constructionTypeId: {
      getMany: (entity: Entity) => getManyReference({
        url: API_URLS.constructionType,
        entity,
        params: { status: DefaultStatus.Active },
      }).then((result) =>
          Object.values(result).reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
      ),
    },
    houseTypeList: {
      getMany: (entity: Entity) => getManyReference({
        url: API_URLS.constructionHouseType,
        entity
      }),
    },
    doubleGlazingList: {
      getMany: (entity: Entity) => getManyReference({
        url: API_URLS.constructionDoubleGlazing,
        entity
      }),
    },
    sectionList: {
      getMany: (entity: Entity) => getManyReference({
        url: API_URLS.sections,
        entity,
        params: { status: DefaultStatus.Active },
      }),
    },
    sashTypeList: {
      getMany: (entity: Entity) => getManyReference({
        url: API_URLS.constructionSashList,
        entity,
        params: { status: DefaultStatus.Active },
      }).then((result) =>
          Object.values(result).reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
      ),
    },
  }
});

export default Construction;
