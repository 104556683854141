import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { Entity, EntityField } from 'icerockdev-admin-toolkit';
import { toJS } from 'mobx';
import styles from '../SolutionViewer/styles';
import { solutionModules, SolutionModulesName } from '~/pages/solution/constants';

type IProps = WithStyles<typeof styles> & {
  url: string;
  id?: string;
  modules: typeof solutionModules;
  errors: Record<string, string>;
  isEditing: boolean;
  isLoading: boolean;
  data: Record<string, any>;
  viewable: boolean;
  entity: Entity;

  setEditorData: (data: Record<string, any>) => void;
  getItem: (id: any) => void;
  cancelGetItem: () => void;
  onSave: () => void;
  onCancel: () => void;
  onResetFieldError: (field: string) => void;
  withToken?: (req: any, args: any) => any;
};

const SolutionModules = withStyles(styles)(
  observer(
    ({
      classes,
      id,
      modules,
      errors,
      onSave,
      onCancel,
      onResetFieldError,
      isLoading,
      data,
      setEditorData,
      getItem,
      cancelGetItem,
      withToken,
      isEditing,
      entity,
    }: IProps) => {
      const isCreating = useMemo(() => typeof id === 'undefined', [id]);

      const { fields } = entity;

      const visibleFields = useMemo(
        () =>
          fields.filter(
            (field) =>
              (isEditing && !isCreating && !field.hideInEdit) ||
              (isCreating && !field.hideInCreate) ||
              (!isEditing && !isCreating && !field.hideInView)
          ),
        [fields, isEditing, isCreating]
      );

      const onFieldChange = useCallback(
        (f) => (value: any) => {
          if (errors[f]) {
            onResetFieldError(f);
          }

          setEditorData({ ...entity.editorData, [f]: value });
        },
        [errors, setEditorData, entity.editorData, onResetFieldError]
      );

      const onSubmit = useCallback(
        (event) => {
          event.preventDefault();
          onSave();
        },
        [onSave]
      );

      useEffect(() => {
        getItem(id);
        return () => cancelGetItem();
      }, [cancelGetItem, getItem, id]);

      if (isLoading) {
        return (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        );
      }

      return (
        <div className={classes.wrap}>
          {data && (
            <form onSubmit={onSubmit}>
              {modules.map(({ title, fields: moduleFields }) => (
                <Paper key={title} className={classes.wrap}>
                  <Typography variant="h4" style={{ padding: '32px 24px' }}>
                    {title}
                  </Typography>
                  <div
                    className={classes.grid}
                    style={{
                      display: SolutionModulesName.model === title ? 'block' : 'grid',
                    }}
                  >
                    {moduleFields.map((moduleField) => {
                      const field = visibleFields.find(({ name }) => name === moduleField);
                      const editorStyle = isCreating
                        ? field?.options?.creatorStyle ?? {}
                        : field?.options?.editorStyle ?? {};
                      const style = toJS(editorStyle) as React.CSSProperties;
                      return (
                        field && (
                          <div className={classes.field} style={style} key={field.name}>
                            {!isEditing && (
                              <div className="label">
                                {field.label || field.name}
                                {isEditing && field.required && <span>{` *`}</span>}
                              </div>
                            )}
                            <div className="field">
                              <EntityField
                                name={field.name}
                                data={data}
                                fields={fields}
                                isEditing={isEditing}
                                error={errors[field.name]}
                                handler={onFieldChange(field.name)}
                                withToken={withToken}
                                entity={entity}
                              />
                            </div>
                          </div>
                        )
                      );
                    })}
                  </div>
                </Paper>
              ))}

              <div className={`${classes.field} ${classes.buttons}`}>
                <Grid container spacing={1}>
                  <Grid item style={{ flex: 1 }} />

                  <Grid item>
                    <Button type="button" color="default" variant="outlined" onClick={onCancel}>
                      Отменить
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button type="submit" variant="contained" color="primary">
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
          )}
        </div>
      );
    }
  )
);

export { SolutionModules };
