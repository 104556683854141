import ExtendedCustomEntity from '~/common/modules/ExtendedCustomEntity';

class ConstructionTypeEntity extends ExtendedCustomEntity {
  emptyText =
    'В системе пока нет созданных типов конструкций. Для начала работы с каталогом типов конструкций создайте новый тип';

  deletionText = 'Вы уверены, что хотите удалить тип конструкции?';

  blockText = 'Вы уверены, что хотите заблокировать тип конструкции?';

  unblockText = 'Вы уверены, что хотите разблокировать тип конструкции?';
}

export default ConstructionTypeEntity;
