import { Config } from 'icerockdev-admin-toolkit';
import auth from '~/auth';
import theme from '~/config/theme';
import Account from '~/pages/account';
import Faq from '~/pages/faq';
import User from '~/pages/user';
import ColorSolution from '~/pages/colorSolution';
import { CustomNotifications } from '~/common/modules/Notification';
import Section from '~/pages/section';
import FittingSolution from '~/pages/fittingSolution';
import SolutionCategory from '~/pages/solutionCategory';
import Solution from '~/pages/solution';
import UserB2C from '~/pages/userB2C';
import ConstructionType from "~/pages/constructionType";
import Construction from "~/pages/construction";

class CustomConfig extends Config {
  notifications = new CustomNotifications();
}

export default new CustomConfig({
  host: process.env.REACT_APP_BASE_URL,
  logo: '/images/logo.svg',
  auth,
  theme,
  pages: [
    User,
    Account,
    ColorSolution,
    FittingSolution,
    Section,
    Construction,
    ConstructionType,
    Solution,
    SolutionCategory,
    UserB2C,
    Faq,
  ],
});
