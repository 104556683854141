import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { EntityFileUpload } from '~/common/components/EntityFileUpload';
import EntityImage from '~/common/components/EntityImage';

type IProps = IEntityFieldProps;

const ConstructionFileUpload: FC<IProps> = ({ ...props }) => (
  <EntityFileUpload
    {...props}
    renderImage={(val) =>
      val && <EntityImage width={65} height={130} val={val} circle={false}/>
    }
  />
);

export default ConstructionFileUpload;
