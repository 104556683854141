import { FaqEntity } from '~/pages/faq/components/FaqEntity';
import { FAQ_FIELDS } from '~/pages/faq/fields';
import { blockItemsFn, deleteItemsFn, fetchItemsFn, getItemsFn, unblockItemsFn } from '~/utils/api';
import { UserRoles } from '~/pages/user/constants';
import { API_URLS } from '~/utils/constants';
import { createItemsFn, updateItemsFn } from '~/pages/faq/api';

const FAQ_URL = API_URLS.faqs;

const User = new FaqEntity({
  title: 'Частые вопросы',
  viewable: true,
  editable: true,
  creatable: true,
  menu: {
    label: 'Частые вопросы',
    url: '/faqs',
    enabled: true,
  },
  api: {
    list: { url: FAQ_URL, method: 'get' },
    get: { url: FAQ_URL, method: 'get' },
    update: { url: FAQ_URL, method: 'put' },
    create: { url: FAQ_URL, method: 'post' },
  },
  fields: FAQ_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  updateItemsFn,
  deleteItemsFn,
  blockItemsFn,
  unblockItemsFn,
});

export default User;
