import React, {FC} from "react";
import {EntityFileUpload} from "~/common/components/EntityFileUpload";
import {IEntityFieldProps} from "icerockdev-admin-toolkit";
import EntityImage from "~/common/components/EntityImage";

type SashUploadProps = IEntityFieldProps & { fileNameFromValue?: string };

const SashFileUpload: FC<SashUploadProps> = ({isEditing, ...props}) => (
    <EntityFileUpload
        {...props}
        isEditing={isEditing}
        renderImage={(val) => val && <EntityImage width={65} height={130} val={val} circle={false}/>}
    />
);

export { SashFileUpload };
