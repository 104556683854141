import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';

import { defaultMinMaxValidator, isEmail, minMax, regexpTest } from '~/utils/validators';
import {
  DEFAULT_MAX_STRING_LENGTH,
  DEFAULT_STATUS_NAMES,
  ONLY_CYRILLIC_SYMBOLS,
} from '~/utils/constants';
import { PHONE_NUMBER_LENGTH } from '~/pages/userB2C/constants';
import EntityButton from '~/common/components/EntityButton';
import PhoneField from '~/pages/userB2C/components/PhoneInput';

export const USER_B2C_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: DEFAULT_STATUS_NAMES,
    hideInCreate: true,
    required: true,
    sortable: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'ФИО',
    required: true,
    validator: (val: string) =>
      minMax(0, DEFAULT_MAX_STRING_LENGTH)(`Должно быть до ${DEFAULT_MAX_STRING_LENGTH} символов`)(
        val
      ),
  },
  {
    type: 'phone',
    name: 'phone',
    label: 'Контактный номер',
    required: true,
    validator: minMax(
      PHONE_NUMBER_LENGTH,
      PHONE_NUMBER_LENGTH
    )(`Должно быть ${PHONE_NUMBER_LENGTH} символов`),
    component: PhoneField,
  },
  {
    type: 'string',
    name: 'email',
    label: 'Электронная почта',
    required: true,
    hideInEdit: true,
    validator: isEmail('Невалидный адрес электронной почты'),
    sortable: true,
  },
  {
    type: 'string',
    name: 'location',
    label: 'Город',
    validator: (val: string) =>
      regexpTest(ONLY_CYRILLIC_SYMBOLS)('Обнаружены недопустимые символы')(val) ||
      defaultMinMaxValidator(val),
    sortable: true,
    required: true
  },
  {
    type: 'date',
    name: 'createdAt',
    label: 'Дата регистрации',
    validator: (val: string) =>
      regexpTest(ONLY_CYRILLIC_SYMBOLS)('Обнаружены недопустимые символы')(val) ||
      defaultMinMaxValidator(val),
    sortable: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  // block and unblock button
  {
    type: 'custom',
    name: 'blockButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="block" />,
  },
  // delete button
  {
    type: 'custom',
    name: 'deleteButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="delete" />,
  },
];
