import { IEntityField } from 'icerockdev-admin-toolkit';
import React from 'react';
import { ALLOWED_IMAGE_EXTENSIONS, MAX_IMAGE_SIZE } from '~/pages/construction/constants';
import ConstructionFileUpload from '~/pages/construction/components/ConstructionFileUpload';
import { ConstructionReferenceSelect } from "~/pages/construction/components/ConstructionReferenceSelect";
import { ConstructionSashList } from "~/pages/construction/components/ConstructionSashList";
import ConstructionTypeIdReferenceSelect
  from "~/pages/construction/components/ConstructionTypeIdReferenceSelect";

export const CONSTRUCTION_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'referenceSelect',
    name: 'constructionTypeId',
    label: 'Тип Конструкции',
    required: true,
    hideInList: true,
    component: ConstructionTypeIdReferenceSelect,
  },
  {
    type: 'number',
    name: 'defaultWidth',
    label: 'Ширина по-умолчанию',
    required: true,
    hideInView: true,
    hideInList: true
  },
  {
    type: 'number',
    name: 'defaultHeight',
    label: 'Высота по-умолчанию',
    required: true,
    hideInView: true,
    hideInList: true
  },
  {
    type: 'custom',
    name: 'image',
    label: 'Картинка для отображения',
    options: {
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
    },
    hideInList: true,
    component: ConstructionFileUpload,
    required: false,
  },
  {
    type: 'referenceSelect',
    name: 'doubleGlazingList',
    label: 'Доступные стеклопакеты',
    required: true,
    hideInList: true,
    component: ConstructionReferenceSelect,
  },
  {
    type: 'referenceSelect',
    name: 'houseTypeList',
    label: 'Доступные типы домов',
    required: true,
    hideInList: true,
    component: ConstructionReferenceSelect,
  },
  {
    type: 'referenceSelect',
    name: 'sectionList',
    label: 'Доступные профили',
    required: true,
    hideInList: true,
    component: ConstructionReferenceSelect,
  },
  {
    type: 'custom',
    name: 'sashList',
    label: 'Створки',
    required: true,
    hideInList: true,
    component: ConstructionSashList,
    options: {
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
    },
  },
  {
    type: 'referenceSelect',
    name: 'sashTypeList',
    label: 'Доступные створки',
    required: false,
    hideInList: true,
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
  },
];
