import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { EntityFileUpload } from '~/common/components/EntityFileUpload';
import EntityImage from '~/common/components/EntityImage';

type SolutionFileUploadProps = IEntityFieldProps & {
  fileNameFromValue?: string;
};

const SolutionFileUpload: FC<SolutionFileUploadProps> = ({ isEditing, ...props }) => (
  <EntityFileUpload
    {...props}
    isEditing={isEditing}
    renderImage={(val) =>
      val && <EntityImage width={isEditing ? 130 : 56} height={isEditing ? 130 : 56} val={val} />
    }
  />
);

export default SolutionFileUpload;
