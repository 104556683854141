import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { EntityField, IEntityField } from 'icerockdev-admin-toolkit';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { SolutionCategoryEntityList } from '~/pages/solutionCategory/components/SolutionCategoryEntityList';
import CustomEntity from '~/common/modules/CustomEntity';
import EmptyList from '~/common/components/EmptyList';

type SolutionCategoryViewerProps = WithStyles<typeof styles> & {
  url: string;
  childUrl: string;
  id?: string;
  fields: IEntityField[];
  childFields: IEntityField[];
  errors: Record<string, string>;
  isEditing: boolean;
  isLoading: boolean;
  data: Record<string, any>;
  childData: Record<string, any>[];
  viewable: boolean;
  entity: CustomEntity;
  childEntity: CustomEntity;

  setEditorData: (data: Record<string, any>) => void;
  getItem: (id: any) => void;
  cancelGetItem: () => void;
  onSave: () => void;
  onCancel: () => void;
  onResetFieldError: (field: string) => void;
  withToken?: (req: any, args: any) => any;
};

const SolutionCategoryViewer = withStyles(styles)(
  observer(
    ({
      classes,
      childUrl,
      id,
      fields,
      childFields,
      errors,
      onSave,
      onCancel,
      onResetFieldError,
      isLoading,
      data,
      childData,
      setEditorData,
      getItem,
      cancelGetItem,
      withToken,
      isEditing,
      entity,
      childEntity,
    }: SolutionCategoryViewerProps) => {
      const { t } = useTranslation();
      const isCreating = useMemo(() => typeof id === 'undefined', [id]);

      const visibleFields = useMemo(
        () =>
          fields.filter(
            (field) =>
              (isEditing && !isCreating && !field.hideInEdit) ||
              (isCreating && !field.hideInCreate) ||
              (!isEditing && !isCreating && !field.hideInView)
          ),
        [fields, isEditing, isCreating]
      );

      const onFieldChange = useCallback(
        (f) => (value: any) => {
          if (errors[f]) {
            onResetFieldError(f);
          }

          setEditorData({ ...data, [f]: value });
        },
        [errors, setEditorData, data, onResetFieldError]
      );

      const onSubmit = useCallback(
        (event) => {
          event.preventDefault();
          onSave();
        },
        [onSave]
      );

      useEffect(() => {
        getItem(id);
        return () => cancelGetItem();
      }, [cancelGetItem, getItem, id]);

      if (isLoading) {
        return (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        );
      }

      return (
        <div className={classes.wrap}>
          {data && (
            <>
              <form onSubmit={onSubmit}>
                <Paper>
                  <div className={classes.grid} style={{ flexWrap: 'wrap' }}>
                    {visibleFields.map((field) => (
                      <div className={classes.field} key={field.name}>
                        {!isEditing && (
                          <div className="label">
                            {field.label || field.name}
                            {isEditing && field.required && <span>{` *`}</span>}
                          </div>
                        )}
                        <div className="field">
                          <EntityField
                            name={field.name}
                            data={data}
                            fields={fields}
                            isEditing={isEditing}
                            error={errors[field.name]}
                            handler={onFieldChange(field.name)}
                            withToken={withToken}
                            entity={entity}
                          />
                        </div>
                      </div>
                    ))}

                    {isEditing && (
                      <div className={`${classes.field} ${classes.buttons}`}>
                        <Grid container spacing={1}>
                          <Grid item style={{ flex: 1 }} />

                          <Grid item>
                            <Button
                              type="button"
                              color="default"
                              variant="outlined"
                              onClick={onCancel}
                            >
                              {t('buttons:Cancel')}
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button type="submit" variant="contained" color="primary">
                              {t('buttons:Save')}
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                </Paper>
              </form>
              {!isEditing && (
                <Paper style={{ marginTop: '20px' }}>
                  <Typography variant="h5" style={{ padding: '32px 24px' }}>
                    Информационные страницы
                  </Typography>
                  {childData.length > 0 ? (
                    <SolutionCategoryEntityList
                      isLoading={isLoading}
                      fields={childFields}
                      data={childData}
                      entity={childEntity}
                      canView
                      canEdit={false}
                      url={childUrl}
                      selected={childEntity.selected}
                      setSelected={childEntity.setSelected}
                      sortBy={entity.sortBy}
                      sortDir={entity.sortDir}
                      onSortChange={entity.setSort}
                    />
                  ) : (
                    <Box style={{ paddingBottom: '20px' }}>
                      <EmptyList isLoading={false} text={childEntity.emptyText} />
                    </Box>
                  )}
                </Paper>
              )}
            </>
          )}
        </div>
      );
    }
  )
);

export { SolutionCategoryViewer };
