import { IEntityField } from 'icerockdev-admin-toolkit';
import React from 'react';
import { defaultMinMaxValidator } from '~/utils/validators';
import { DEFAULT_STATUS_NAMES } from '~/utils/constants';
import { ALLOWED_IMAGE_EXTENSIONS, MAX_IMAGE_SIZE } from '~/pages/colorSolution/constants';
import EntityButton from '~/common/components/EntityButton';
import ColorSolutionFileUpload from '~/pages/colorSolution/components/ColorSolutionFileUpload';
import EntityImage from '~/common/components/EntityImage';

export const COLOR_SOLUTION_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: DEFAULT_STATUS_NAMES,
    hideInCreate: true,
    required: true,
    sortable: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Название',
    validator: defaultMinMaxValidator,
    required: true,
  },
  {
    type: 'custom',
    name: 'image',
    label: 'Картинка для отображения',
    options: {
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
    },
    hideInList: true,
    component: ColorSolutionFileUpload,
    required: true,
  },
  {
    type: 'custom',
    name: 'imageUrl',
    label: 'Картинка для отображения',
    hideInCreate: true,
    hideInEdit: true,
    hideInView: true,
    component: ({ value }) => <EntityImage width={32} height={32} val={value} />,
  },

  // block and unblock button
  {
    type: 'custom',
    name: 'blockButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="block" />,
  },
  // delete button
  {
    type: 'custom',
    name: 'deleteButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="delete" />,
  },
];
