import { IEntityField } from 'icerockdev-admin-toolkit';
import React from 'react';
import {
  DEFAULT_MAX_STRING_LENGTH,
  DEFAULT_MIN_STRING_LENGTH,
  DEFAULT_STATUS_NAMES,
} from '~/utils/constants';
import { minMaxLength } from '~/utils/validators';
import { ALLOWED_IMAGE_EXTENSIONS, MAX_IMAGE_SIZE } from '~/pages/constructionType/constants';
import ConstructionTypeFileUpload from '~/pages/constructionType/components/ConstructionTypeFileUpload';
import EntityImage from '~/common/components/EntityImage';
import { ConstructionButton } from "~/pages/constructionType/components/ConstructionCreateOrUpdateButton";

export const CONSTRUCTION_TYPE_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: DEFAULT_STATUS_NAMES,
    hideInCreate: true,
    required: true,
    sortable: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Название',
    validator: (val: string): string =>
      minMaxLength(
        DEFAULT_MIN_STRING_LENGTH,
        DEFAULT_MAX_STRING_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${DEFAULT_MAX_STRING_LENGTH} символов`)(
        val?.trim()
      ),
    required: true,
    sortable: true,
  },
  {
    type: 'custom',
    name: 'image',
    label: 'Картинка для отображения',
    options: {
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
    },
    hideInList: true,
    component: ConstructionTypeFileUpload,
    required: true,
  },
  {
    type: 'custom',
    name: 'imageUrl',
    label: 'Картинка для отображения',
    hideInCreate: true,
    hideInEdit: true,
    hideInView: true,
    component: ({ value }) => <EntityImage width={32} height={32} val={value} />,
  },
  {
    type: 'custom',
    name: 'constructionId',
    label: ' ',
    hideInCreate: true,
    hideInEdit: true,
    hideInView: true,
    component: (props) => <ConstructionButton {...props} />,
  },
];
