import { API_URLS } from '~/utils/constants';
import { CONSTRUCTION_TYPE_FIELDS } from '~/pages/constructionType/fields';
import { fetchItemsFn, getItemsFn } from '~/utils/api';
import ConstructionTypeEntity from './components/ConstructionTypeEntity';
import { createItemsFn, updateItemsFn } from '~/pages/constructionType/api';

const CONSTRUCTION_TYPE_URL = API_URLS.constructionType;
const TITLE = 'Типы конструкций';

const ConstructionType = new ConstructionTypeEntity({
  title: TITLE,
  viewable: false,
  editable: true,
  creatable: true,
  selectable: false,
  menu: {
    label: TITLE,
    url: '/construction-type',
    enabled: true,
  },
  api: {
    list: { url: CONSTRUCTION_TYPE_URL, method: 'get' },
    get: { url: CONSTRUCTION_TYPE_URL, method: 'get' },
    update: { url: CONSTRUCTION_TYPE_URL, method: 'put' },
    create: { url: CONSTRUCTION_TYPE_URL, method: 'post' },
  },
  fields: CONSTRUCTION_TYPE_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  updateItemsFn,
});

export default ConstructionType;
