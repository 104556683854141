import React, { FC, useCallback, useMemo, useState } from "react";
import { EntityFieldSelect, IEntityFieldProps} from "icerockdev-admin-toolkit";
import styles from './styles.module.scss';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {Button, FormControl, FormHelperText} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { NumberInput } from "~/pages/construction/components/ConstructionSashList/NumberInput";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { SashFileUpload } from "~/pages/construction/components/ConstructionSashList/SashFileUpload";

const ConstructionSashList: FC<IEntityFieldProps> = observer(
    ({ value, data , entity, handler, fields, isEditing, options, error}) => {
    const sashList = useMemo(
        () => value ?? [],
        [data?.id]
    );
    const [selectedSashList, setSelectedSashList] = useState(sashList);
    const onAdd = useCallback(() => {
        if (!handler) return;
        const newValue = toJS(value) || [];
        newValue.push({
            sashId: null,
            number: null,
            imageKey: null,
            offsetX: null,
            offsetY: null,
        });
        setSelectedSashList(newValue);
        handler(newValue);
    }, [handler, value, entity, selectedSashList, setSelectedSashList]);

    const onDelete = useCallback((index: number) => {
            if (!handler) return;
            setSelectedSashList((prevState) => {
                    const state = prevState.filter((item, itemIndex) => {
                        return index !== itemIndex;
                    })
                    handler(toJS(state))
                    return state
                }
            );
        },
        [handler, value ,setSelectedSashList]
    );

    const onChangeValue = useCallback(
        (index: number, selectedValue: number | string | undefined, field: string) => {
            if (!handler) return;

            const element = value[index];
            if(!element) return;
            Object.assign(element, {[field]: selectedValue});
            const newValue = toJS(value) || [];
            newValue[index] = element

            setSelectedSashList(newValue);
            handler(newValue);
        },
        [handler, value]
    );

        const handleSolutionImageChange = useCallback(
            (index: number) => (file: File) => {
                if (!handler) return;
                setSelectedSashList((prevState) => {
                        const state = prevState.map((el, itemIndex) => (index === itemIndex ? { ...el, image: file } : el))
                        handler(toJS(state))
                        return state
                    }
                );
            },
            [handler, selectedSashList, setSelectedSashList]
        );

    return (
        <FormControl variant="outlined">
            {selectedSashList &&
                selectedSashList.map((sash, index) => (
                    <List className={styles.sashList} key={`constructionSash-${index}`}>
                        <ListItem>
                            <SashFileUpload
                                name={'image'}
                                fields={fields}
                                data={sash}
                                isEditing={isEditing}
                                options={options}
                                handler={handleSolutionImageChange(index)}
                                fileNameFromValue={sash.imageKey ? ' ' : undefined}
                            />
                            <NumberInput
                                label="Номер створки"
                                onChange={(val) => { onChangeValue(index, val, "number")}}
                                value={sash.number}
                            />
                            <EntityFieldSelect
                                label={"Тип створки"}
                                name={"sashTypeList"}
                                value={sash.sashId}
                                isEditing={isEditing}
                                entity={entity}
                                fields={fields}
                                options={entity?.referenceData["sashTypeList"] ?? []}
                                handler={(val) => {onChangeValue(index, val, "sashId")}}
                            />
                            <NumberInput
                                label="Отступ слева"
                                onChange={(val) => {onChangeValue(index, val, "offsetX")}}
                                value={sash.offsetX}
                            />
                            <NumberInput
                                label="Отступ сверху"
                                onChange={(val) => {onChangeValue(index, val, "offsetY")}}
                                value={sash.offsetY}
                            />
                            <Button
                                variant="contained"
                                onClick={() => {
                                    onDelete(index)
                                }}
                                style={{ marginLeft: '10px' }}
                            >
                                <DeleteIcon />
                            </Button>
                        </ListItem>
                    </List>
                ))}
            {!sashList.length && <div>Для данного типа конструкции отсутствуют створки.</div>}
            <Button
                variant="contained"
                name={"add"}
                onClick={onAdd}
                style={{ marginLeft: '10px', width: '30%' }}
            >
                Добавить
            </Button>
            <FormHelperText error={!!error}>{error}</FormHelperText>
        </FormControl>
    );
});

export { ConstructionSashList };
