import React, { FC } from 'react';

const EntityImage: FC<{ val: string; width: number; height: number; circle?: boolean | null }> = ({ val, width, height, circle = true }) =>
  val ? ( circle ?
    <img src={val} style={{ borderRadius: '100%', width, height }} alt="Картинка для отображения" />
          : <img src={val} style={{ width, height }} alt="Картинка для отображения" />
  ) : (
    <>Изображение не задано</>
  );

export default EntityImage;
