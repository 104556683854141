/* eslint-disable react/jsx-no-duplicate-props */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TextField } from '@material-ui/core';

import { useEntity } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { useDebounce } from '~/utils/hooks';

type ListFiltersProps = {
  INPUTS: any[];
};

const ListFilters: FC<ListFiltersProps> = ({ INPUTS }) => {
  const [filtersState, setFiltersState] = useState<Record<string, string>>({});

  const handleChange = useCallback(
    (maxLength) => (e) => {
      const { name, value } = e.target;

      if (value.length > maxLength) return;

      setFiltersState((prevState) => ({ ...prevState, [name]: value }));
    },
    []
  );

  const entity = useEntity();

  const debouncedFiltersState = useDebounce(filtersState, 500);

  const filters = useMemo(
    () => Object.entries(debouncedFiltersState).map((el) => ({ name: el[0], value: el[1] })),
    [debouncedFiltersState]
  );

  useEffect(() => {
    if (!filters.length) return;
    entity.setFilters(filters);
    entity.applyFilter();
  }, [debouncedFiltersState]);

  return (
    <div className={styles.filters}>
      {INPUTS.map(({ label, name, maxLength, type }) => (
        <div className={styles.filters__inputContainer} key={name}>
          <TextField
            label={label}
            type="search"
            variant="outlined"
            onChange={handleChange(maxLength)}
            value={filtersState[name]}
            InputProps={{
              className: styles.filters__input,
            }}
            inputProps={{ name, type }}
          />
          <img src="images/search-icon.svg" alt="search" />
        </div>
      ))}
    </div>
  );
};

export default ListFilters;
