import { API_URLS, DefaultStatus } from '~/utils/constants';
import {
  blockItemsFn,
  deleteItemsFn,
  fetchItemsFn,
  getItemsFn,
  getManyReference,
  unblockItemsFn,
} from '~/utils/api';
import SolutionEntity from './components/SolutionEntity';
import { createItemsFn, updateItemsFn } from '~/pages/solution/api';
import { SOLUTION_FIELDS } from '~/pages/solution/fields';

const SOLUTION_URL = API_URLS.solutions;
const TITLE = 'Информационные страницы';
const PARENT_TITLE = 'Разделы информационных страниц';

const Solution = new SolutionEntity({
  title: TITLE,
  parentTitle: PARENT_TITLE,
  viewable: true,
  editable: true,
  creatable: true,
  menu: {
    label: TITLE,
    url: '/solution-categories/:solutionCategoryId/solutions',
    parentUrl: '/solution-categories/:solutionCategoryId',
    enabled: false,
  },
  api: {
    list: { url: SOLUTION_URL, method: 'get' },
    get: { url: SOLUTION_URL, method: 'get' },
    update: { url: SOLUTION_URL, method: 'put' },
    create: { url: SOLUTION_URL, method: 'post' },
    delete: { url: SOLUTION_URL, method: 'delete' },
  },
  references: {
    sectionId: {
      getMany: (entity) =>
        getManyReference({
          url: API_URLS.sections,
          entity,
          params: { status: DefaultStatus.Active },
        }).then((result) =>
          Object.values(result).reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
        ),
    },
    colorSolutions: {
      getMany: (entity) =>
        getManyReference({
          url: API_URLS.colorSolution,
          entity,
          params: { status: DefaultStatus.Active },
        }),
    },
    fittingSolutions: {
      getMany: (entity) =>
        getManyReference({
          url: API_URLS.fittingSolution,
          entity,
          params: { status: DefaultStatus.Active },
        }),
    },
  },
  fields: SOLUTION_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
  deleteItemsFn,
  updateItemsFn,
});

export default Solution;
