// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import {
  Entity,
  ENTITY_ERRORS,
  IEntityCreateFunctionResult,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import { action, flow, observable, reaction, toJS } from 'mobx';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import messages from '~/locales/ru/messages.json';
import CustomEntity from '~/common/modules/CustomEntity';

export class FaqEntity extends CustomEntity {
  @observable emptyText =
    'В системе пока нет созданных частых вопросов. Для начала работы с каталогом частых вопросов создайте вопрос';

  deletionText = 'Вы уверены, что хотите удалить частый вопрос?';

  blockText = 'Вы уверены, что хотите заблокировать частый вопрос?';

  unblockText = 'Вы уверены, что хотите разблокировать частый вопрос?';

  @action
  createItem = () => {
    this.updateItemInstance = flow(function* (this: Entity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, true)) {
          throw new Error(messages['Check all fields']);
        }

        if (!this.api?.create?.url || !this.createItemsFn) {
          throw new Error(messages['Items load failed']);
        }
        const result: IEntityCreateFunctionResult = yield this.parent?.auth?.withToken(
          this.createItemsFn,
          {
            url: this.api?.create?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || messages['Items load failed']);
        this.fetchItems();
        this.parent?.history.push(this.menu.url);
        this.parent?.notifications.showSuccess(`Вопрос успешно создан`);
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  updateItem = () => {
    if (!window.confirm('Вы уверены, что хотите редактировать вопрос?')) return;

    this.updateItemInstance = flow(function* updateItems(this: Entity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, false)) {
          throw new Error(i18n.t(ENTITY_ERRORS.INCORRECT_INPUT));
        }

        if (!this.api?.update?.url || !this.updateItemsFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        const result: IEntityUpdateFunctionResult = yield this.parent?.auth?.withToken(
          this.updateItemsFn,
          {
            url: this.api?.update?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

        this.fetchItems();

        if (this.parent?.history?.length && this.parent?.history.goBack) {
          this.parent?.history.goBack();
        } else if (this.parent?.history?.push) {
          this.parent?.history.push(this.menu.url);
        }
      } catch (e) {
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  onMount = () => {
    this.getFiltersFromHash();
    reaction(
      () => [this.filters, this.sortBy, this.sortDir, this.page, this.items],
      this.setFiltersWindowHash
    );
    reaction(
      () => [this.filters],
      () => {
        this.emptyText = 'По вашему запросу подходящего вопроса в системе не найдено';
      }
    );
    reaction(() => [this.items, this.sortBy, this.sortDir], this.applyFilter);
    reaction(() => this.page, this.fetchItems);
    this.fetchItems();
  };
}
