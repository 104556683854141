import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityUpdateFunction,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const createItemsFn = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.post(
    url,
    {
      ...data,
      surname: data.surname.trim(),
      name: data.name.trim(),
      secondName: data.secondName?.trim(),
    },
    {
      headers: { authorization: token ?? '' },
    }
  );

  return { data: result.data.data };
};

export const updateItemsFn: IEntityUpdateFunction = async ({ url, token, data }) => {
  const result = await axios.put(
    `${url}/${data.id}`,
    {
      ...data,
      surname: data.surname.trim(),
      name: data.name.trim(),
      secondName: data.secondName?.trim(),
    },
    {
      headers: { authorization: token ?? '' },
    }
  );
  return {
    data: result.data,
  };
};
