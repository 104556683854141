/* eslint-disable no-shadow */

export const QUESTION_MIN_LENGTH = 3;
export const QUESTION_MAX_LENGTH = 500;

export const ANSWER_MIN_LENGTH = 3;
export const ANSWER_MAX_LENGTH = 1000;

export enum FaqPage {
  CATALOG = 'CATALOG',
  SUPPORT = 'SUPPORT',
}

export const FAQ_PAGE_NAMES = {
  [FaqPage.CATALOG]: 'Каталог',
  [FaqPage.SUPPORT]: 'Поддержка',
};

export enum FaqRole {
  B2B = 'B2B',
  B2C = 'B2C',
}

export const FAQ_ROLE_NAMES = {
  [FaqRole.B2B]: 'Для B2B',
  [FaqRole.B2C]: 'Для B2C',
};
