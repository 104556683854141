import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Chip, Typography } from '@material-ui/core';
import styles from './styles.module.scss';
import { EntityFieldReferenceSelect } from '~/common/components/EntityFieldReferenceSelect';

const ConstructionReferenceSelect: FC<IEntityFieldProps> = ({
  label,
  name,
  value,
  handler,
  error,
  entity,
  fields,
  options,
  data,
}) => {
    const referenceData = useMemo(
        () => (options ? Object.values((options!.referenceData as Record<string, any>) || {}) : []),
        [options!.referenceData]
    );

    const initialSelectedValues = useMemo<number[]>(
        () => (value ? value.map((id) => id as number) : []),
        [value]
    );

    const [selectedValues, setSelectedValues] = useState<number[]>(initialSelectedValues);

    const handleSelectChange = useCallback(
        (id: string) => setSelectedValues((prevState) => [...new Set([...prevState, +id])]),
        [setSelectedValues]
    );

    const handleDelete = useCallback(
        (deletedId: number) => {
            setSelectedValues((prevState) => prevState.filter((id) => id !== deletedId));
        },
        [setSelectedValues]
    );

    const normalizedOptions = useMemo(
        () =>
            referenceData.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.id]: curr.name,
                }),
                {}
            ),
        [referenceData]
    );

    useEffect(() => {
        if (handler) handler(selectedValues);
    }, [selectedValues]);

    const getName = useCallback(
        (id: number) => referenceData.find((el) => el.id === id)?.name,
        [referenceData]
    );

  return (
    <div>
      <Typography variant="h6" gutterBottom>
          {label}
      </Typography>
      <EntityFieldReferenceSelect
        fields={fields}
        label={label}
        name={name}
        value={value}
        handler={handleSelectChange}
        error={error}
        isEditing
        entity={entity}
        options={normalizedOptions}
      />
      <div className={styles.selectedValues}>
        {selectedValues.map((id) => (
          <Chip
            key={id}
            label={getName(id)}
            onDelete={() => handleDelete(id)}
            color="primary"
          />
        ))}
      </div>
    </div>
  );
};

export { ConstructionReferenceSelect };
