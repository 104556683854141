import { API_URLS } from '~/utils/constants';
import { UserB2CEntity } from './components/UserB2CEntity';
import { USER_B2C_FIELDS } from './fields';
import { blockItemsFn, deleteItemsFn, fetchItemsFn, getItemsFn, unblockItemsFn } from '~/utils/api';
import { createItemsFn, updateItemsFn } from '~/pages/userB2C/api';

const USERB2C_URL = API_URLS.userb2c;

const UserB2C = new UserB2CEntity({
  title: 'Пользователи B2C',
  viewable: true,
  editable: true,
  creatable: true,
  menu: {
    label: 'Пользователи B2C',
    url: '/usersb2c',
    enabled: true,
  },
  api: {
    list: { url: USERB2C_URL, method: 'get' },
    get: { url: USERB2C_URL, method: 'get' },
    update: { url: USERB2C_URL, method: 'put' },
    create: { url: USERB2C_URL, method: 'post' },
  },
  fields: USER_B2C_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  updateItemsFn,
  deleteItemsFn,
  blockItemsFn,
  unblockItemsFn,
});

export default UserB2C;
