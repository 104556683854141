import ExtendedCustomEntity from '~/common/modules/ExtendedCustomEntity';
import { action, flow } from "mobx";
import { Entity, ENTITY_REFERENCE_FIELDS } from "icerockdev-admin-toolkit";


class ConstructionEntity extends ExtendedCustomEntity {
    @action
    fetchItems = (): void => {
        this.fetchItemsInstance = flow(function* (this: Entity) {
            this.isLoading = true;
            this.error = '';
            this.selected = [];

            try {
                // Loading references (if any)
                const references = this.fields
                    .filter(
                        (field) =>
                            field.type &&
                            Object.prototype.hasOwnProperty.call(ENTITY_REFERENCE_FIELDS, field.type) &&
                            this.references[field.name]?.getMany
                    )
                    .map(async (field) => ({
                        [field.name]: await this.references[field.name].getMany(this),
                    }));

                const refResults = yield Promise.all(references);

                this.referenceData = refResults.reduce(
                    (obj: Record<string, any>, res: Record<string, any>) => ({
                        ...obj,
                        ...res,
                    }),
                    {}
                );

                // updating field reference data
                this.fields = this.fields.map((field) =>
                    this.referenceData[field.name]
                        ? {
                            ...field,
                            options: {...field.options, referenceData: this.referenceData[field.name]},
                        }
                        : field
                );
                // TODO dirty fix for incorrect visible data in construction view
                setTimeout(() => { this.isLoading = false; }, 1500);
                // finished
                // this.isLoading = false;
            } catch (e) {
                this.parent?.notifications.showError(e.message);
                this.isLoading = false;
            }
        }).bind(this)();
    }
}

export default ConstructionEntity;
