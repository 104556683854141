import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

type SolutionCategoryCreateSolutionProps = {
  url: string;
};

export const SolutionCategoryCreateSolutionButton: FC<SolutionCategoryCreateSolutionProps> = ({
  url,
}) => (
  <Button
    type="button"
    variant="contained"
    color="primary"
    component={RouterLink}
    to={`${url}/create`}
  >
    Создать инфо. страницу
  </Button>
);
