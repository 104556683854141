// eslint-disable-next-line no-shadow
export enum SectionsModulesName {
  common = 'Общее',
  characteristics = 'Характеристики',
  model = '3D модель',
  details = 'Подробное описание',
  photos = 'Фотографии',
}

// eslint-disable-next-line no-shadow
export enum SectionsModulesUrls {
  common = 'common',
  characteristics = 'characteristics',
  model = 'model',
  details = 'details',
  photos = 'photos',
}

// eslint-disable-next-line no-shadow
export enum SectionEnergyEfficiency {
  Aplusplusplus = 'A+++',
  Aplusplus = 'A++',
  Aplus = 'A+',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export const sectionsModules = [
  {
    title: SectionsModulesName.common,
    url: SectionsModulesUrls.common,
    fields: ['name', 'status', 'previewImg', 'image', 'previewImgUrl', 'imageUrl'],
  },
  {
    title: SectionsModulesName.characteristics,
    url: SectionsModulesUrls.characteristics,
    fields: [
      'certificates',
      'energyEfficiencyClass',
      'mountingWidth',
      'combinationWidth',
      'sealingCircuitCount',
      'cellCount',
      'fold',
      'doubleGlazedWindowHeight',
      'heatTransferResistance',
      'gasketMaterial',
      'doubleGlazedWindowType',
      'colorWays',
      'gasketColor',
    ],
  },
  {
    title: SectionsModulesName.model,
    url: SectionsModulesUrls.model,
    fields: ['show3d', 'model3D', 'colorSolutions', 'fittingSolutions', 'colorSolutionsUrl'],
  },
  {
    title: SectionsModulesName.details,
    url: SectionsModulesUrls.details,
    fields: [
      'shortDescription',
      'advantages',
      'profileSystem',
      'functionality',
      'scope',
      'accessories',
      'physicalProperties',
      'heatTransferResistanceDescription',
      'seals',
      'glassUnit',
      'fittings',
      'beams',
    ],
  },
  {
    title: SectionsModulesName.photos,
    url: SectionsModulesUrls.photos,
    fields: [
      'examplesOfExecutions',
      'examplesOfConstructions',
      'examplesOfExecutionsUrls',
      'examplesOfConstructionsUrls',
    ],
  },
];

export const MAX_IMAGE_SIZE = 1024 * 1024 * 10;
export const MAX_MODEL_SIZE = 1024 * 1024 * 15;
export const MAX_IMAGES = 8;
export const ALLOWED_IMAGE_EXTENSIONS = ['image/png', 'image/jpg', 'image/jpeg'];
export const ALLOWED_MODEL_EXTENSIONS = ['application/zip', 'application/x-zip-compressed'];
