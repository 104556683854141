import { EntityFieldReferenceSelect, IEntityField } from 'icerockdev-admin-toolkit';
import React from 'react';
import { SolutionDetailDescription } from '~/pages/solution/components/SolutionDetailDescription';
import { DEFAULT_MIN_STRING_LENGTH, DEFAULT_STATUS_NAMES } from '~/utils/constants';
import { max, minMax } from '~/utils/validators';
import {
  ALLOWED_BACKGROUND_EXTENSIONS,
  ALLOWED_ICON_EXTENSIONS,
  ALLOWED_IMAGE_EXTENSIONS,
  ALLOWED_MODEL_EXTENSIONS,
  ALLOWED_PREVIEW_IMAGE_EXTENSIONS,
  DetailDescription,
  MAX_BACKGROUND_SIZE,
  MAX_COLOR_LENGTH,
  MAX_DETAIL_DESCRIPTION_ITEMS,
  MAX_DETAIL_DESCRIPTION_LENGTH,
  MAX_FITTINGS_LENGTH,
  MAX_FOLD_LENGTH,
  MAX_GLASS_UNIT_LENGTH,
  MAX_ICON_SIZE,
  MAX_IMAGE_SIZE,
  MAX_MODEL_SIZE,
  MAX_NAME_LENGTH,
  MAX_PREVIEW_IMAGE_SIZE,
  MAX_SHORT_DESCRIPTION_LENGTH,
} from '~/pages/solution/constants';
import EntityImage from '~/common/components/EntityImage';
import SolutionFileUpload from '~/pages/solution/components/SolutionFileUpload';
import { SolutionColorSolutionList } from '~/pages/solution/components/SolutionColorSolutionList';
import { SolutionFittingSolutionList } from '~/pages/solution/components/SolutionFittingSolutionList';
import { SolutionColorSolutions } from '~/pages/solution/components/SolutionColorSolutions';
import { SolutionFittingSolutions } from '~/pages/solution/components/SolutionFittingSolutions';

export const SOLUTION_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Название',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_NAME_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_NAME_LENGTH} символов`)(val?.trim()),
    required: true,
    options: {
      viewerStyle: { gridColumn: 'span 2' },
      creatorStyle: { gridColumn: 'span 3' },
      editorStyle: { gridColumn: 'span 2' },
    },
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: DEFAULT_STATUS_NAMES,
    hideInCreate: true,
    required: true,
    sortable: true,
  },
  {
    type: 'textarea',
    name: 'shortDescription',
    label: 'Краткое описание',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_SHORT_DESCRIPTION_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_SHORT_DESCRIPTION_LENGTH} символов`)(
        val?.trim()
      ),
    required: false,
    hideInList: true,
    options: {
      viewerStyle: { gridColumn: 'span 2' },
      creatorStyle: { gridColumn: 'span 3' },
      editorStyle: { gridColumn: 'span 2' },
    },
  },
  {
    type: 'custom',
    component: SolutionDetailDescription,
    name: 'detailDescription',
    label: 'Заголовок детального описания',
    validator: (val: DetailDescription): string =>
      minMax(
        0,
        MAX_DETAIL_DESCRIPTION_LENGTH
      )(`Должно быть от 0 до ${MAX_DETAIL_DESCRIPTION_LENGTH} символов`)(val.title?.trim() || '') ||
      max(MAX_DETAIL_DESCRIPTION_ITEMS)(
        `Количество элементов должно быть не больше ${MAX_DETAIL_DESCRIPTION_ITEMS}`
      )(val.items.length),
    required: false,
    hideInList: true,
    options: {
      viewerStyle: { gridColumn: 'span 2' },
      creatorStyle: { gridColumn: 'span 3' },
      editorStyle: { gridColumn: 'span 3' },
    },
  },
  {
    type: 'custom',
    name: 'icon',
    label: 'Иконка',
    options: {
      maxSize: MAX_ICON_SIZE,
      allowedMimeType: ALLOWED_ICON_EXTENSIONS,
      keyProp: 'iconKey',
      creatorStyle: { gridColumn: 'span 2' },
      editorStyle: { gridColumn: 'span 2' },
    },
    hideInList: true,
    hideInView: true,
    component: SolutionFileUpload,
    required: true,
  },
  {
    type: 'custom',
    name: 'iconUrl',
    label: 'Иконка',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: ({ value }) => <EntityImage width={32} height={32} val={value} />,
  },
  {
    type: 'custom',
    name: 'image',
    label: 'Картинка интерактивной модели',
    options: {
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
      creatorStyle: { gridColumn: 'span 1' },
      editorStyle: { gridColumn: 'span 1' },
    },
    hideInList: true,
    hideInView: true,
    component: SolutionFileUpload,
    required: true,
  },
  {
    type: 'custom',
    name: 'imageUrl',
    label: 'Картинка интерактивной модели',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: ({ value }) => <EntityImage width={32} height={32} val={value} />,
  },
  {
    type: 'custom',
    name: 'previewImage',
    label: 'Картинка разводной страницы',
    options: {
      maxSize: MAX_PREVIEW_IMAGE_SIZE,
      allowedMimeType: ALLOWED_PREVIEW_IMAGE_EXTENSIONS,
      keyProp: 'previewImageKey',
      creatorStyle: { gridColumn: 'span 2' },
      editorStyle: { gridColumn: 'span 2' },
    },
    hideInList: true,
    hideInView: true,
    component: SolutionFileUpload,
    required: true,
  },
  {
    type: 'custom',
    name: 'previewImageUrl',
    label: 'Картинка разводной страницы',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: ({ value }) => <EntityImage width={32} height={32} val={value} />,
  },
  {
    type: 'custom',
    name: 'background',
    label: 'Картинка для заднего фона',
    options: {
      maxSize: MAX_BACKGROUND_SIZE,
      allowedMimeType: ALLOWED_BACKGROUND_EXTENSIONS,
      keyProp: 'backgroundKey',
      creatorStyle: { gridColumn: 'span 1' },
      editorStyle: { gridColumn: 'span 1' },
    },
    hideInList: true,
    hideInView: true,
    component: SolutionFileUpload,
    required: true,
  },
  {
    type: 'custom',
    name: 'backgroundUrl',
    label: 'Картинка для заднего фона',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: ({ value }) => <EntityImage width={32} height={32} val={value} />,
  },

  {
    type: 'referenceSelect',
    name: 'sectionId',
    label: 'Профиль',
    required: false,
    hideInList: true,
    component: EntityFieldReferenceSelect,
  },
  {
    type: 'string',
    name: 'fold',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_FOLD_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_FOLD_LENGTH} символов`)(val?.trim()),
    label: 'Количество камер',
    required: false,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'glassUnit',
    label: 'Стеклопакет',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_GLASS_UNIT_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_GLASS_UNIT_LENGTH} символов`)(
        val?.trim()
      ),
    required: false,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'fittings',
    label: 'Фурнитура',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_FITTINGS_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_FITTINGS_LENGTH} символов`)(
        val?.trim()
      ),
    required: false,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'color',
    label: 'Исполнение',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_COLOR_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_COLOR_LENGTH} символов`)(val?.trim()),
    required: false,
    hideInList: true,
  },
  {
    type: 'referenceSelect',
    name: 'colorSolutions',
    label: 'Доступные цветовые решения',
    hideInList: true,
    hideInView: true,
    component: SolutionColorSolutions,
    options: {
      maxSize: MAX_MODEL_SIZE,
      allowedMimeType: ALLOWED_MODEL_EXTENSIONS,
    },
  },
  {
    type: 'custom',
    name: 'colorSolutionList',
    label: 'Доступные цветовые решения',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: SolutionColorSolutionList,
  },
  {
    type: 'referenceSelect',
    name: 'fittingSolutions',
    label: 'Доступные варианты фурнитуры',
    hideInList: true,
    hideInView: true,
    component: SolutionFittingSolutions,
    options: {
      maxSize: MAX_MODEL_SIZE,
      allowedMimeType: ALLOWED_MODEL_EXTENSIONS,
    },
  },
  {
    type: 'custom',
    name: 'fittingSolutionList',
    label: 'Доступные варианты фурнитуры',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: SolutionFittingSolutionList,
  },
];
