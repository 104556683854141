import React, {FC} from "react";
import {Button} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import {IEntityFieldProps} from "icerockdev-admin-toolkit";

export const ConstructionButton: FC<IEntityFieldProps> = ({
 data
}) => (
    data?.constructionId ? <Button
        type="button"
        variant="contained"
        color="default"
        component={RouterLink}
        to={`/construction/${data?.constructionId}/edit`}
        onClick={(e) => {
            e.stopPropagation();
        }
    }
    >
        Изменить конструкцию
    </Button> : <Button
        type="button"
        variant="contained"
        color="primary"
        component={RouterLink}
        to={`/construction/create?constructionTypeId=${data?.id}`}
        onClick={(e) => {
            e.stopPropagation();
        }
    }
    >
        Создать конструкцию
    </Button>
);
