import { API_URLS } from '~/utils/constants';
import { blockItemsFn, deleteItemsFn, fetchItemsFn, getItemsFn, unblockItemsFn } from '~/utils/api';
import SolutionCategoryEntity from './components/SolutionCategoryEntity';
import { createItemsFn, updateItemsFn } from '~/pages/solutionCategory/api';
import {
  SOLUTION_CATEGORY_FIELDS,
  SOLUTION_CATEGORY_SOLUTIONS_FIELDS,
} from '~/pages/solutionCategory/fields';
import Solution from '~/pages/solution';

const SOLUTION_CATEGORY_URL = API_URLS.solutionCategory;
const TITLE = 'Разделы информационных страниц';

const SolutionCategory = new SolutionCategoryEntity({
  title: TITLE,
  viewable: true,
  editable: true,
  creatable: true,
  menu: {
    label: TITLE,
    url: '/solution-categories',
    childUrl: Solution.menu.url,
    enabled: true,
  },
  api: {
    list: { url: SOLUTION_CATEGORY_URL, method: 'get' },
    get: { url: SOLUTION_CATEGORY_URL, method: 'get' },
    update: { url: SOLUTION_CATEGORY_URL, method: 'put' },
    create: { url: SOLUTION_CATEGORY_URL, method: 'post' },
  },
  childEntity: Solution,
  fields: SOLUTION_CATEGORY_FIELDS,
  childFields: SOLUTION_CATEGORY_SOLUTIONS_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
  deleteItemsFn,
  updateItemsFn,
});

export default SolutionCategory;
