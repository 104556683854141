import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Chip, Typography } from '@material-ui/core';
import { EntityFieldReferenceSelect } from '~/common/components/EntityFieldReferenceSelect';
import styles from './styles.module.scss';

const SectionFittingSolutions: FC<IEntityFieldProps> = ({
  label,
  name,
  value,
  handler,
  error,
  entity,
  fields,
  options,
  data,
}) => {
  const referenceData = useMemo(
    () => (options ? Object.values((options!.referenceData as Record<string, any>) || {}) : []),
    [options!.referenceData]
  );
  const isCreating = window.location.pathname.includes('create');
  const initialSelectedSolutions = useMemo(() => (value && !isCreating ? value : []), []);
  const [selectedSolutions, setSelectedSolutions] = useState<number[]>(initialSelectedSolutions);

  const handleSelectChange = useCallback(
    (id: string) => setSelectedSolutions((prevState) => [...new Set([...prevState, +id])]),
    [setSelectedSolutions]
  );

  const handleSolutionDelete = useCallback(
    (deletedId: number) => {
      setSelectedSolutions((prevState) => prevState.filter((id) => id !== deletedId));
    },
    [setSelectedSolutions]
  );

  const normalizedOptions = useMemo(
    () =>
      referenceData.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr.name,
        }),
        {}
      ),
    [referenceData]
  );

  useEffect(() => {
    handler!(selectedSolutions);
  }, [selectedSolutions]);

  const getSolutionName = useCallback(
    (id: number) => referenceData.find((el) => el.id === id)?.name,
    [referenceData]
  );

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Цветовые фурнитуры
      </Typography>
      <EntityFieldReferenceSelect
        fields={fields}
        label={label}
        name={name}
        value={value}
        handler={handleSelectChange}
        error={error}
        isEditing
        entity={entity}
        options={normalizedOptions}
      />
      <div className={styles.selectedSolutions}>
        {selectedSolutions.map((id) => (
          <Chip
            key={id}
            label={getSolutionName(id)}
            onDelete={() => handleSolutionDelete(id)}
            color="primary"
          />
        ))}
      </div>
    </div>
  );
};

export { SectionFittingSolutions };
