import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames';
import { FeatureInputProps, Placeholder } from 'icerockdev-admin-toolkit';
import styles from 'icerockdev-admin-toolkit/src/application/modules/pages/Feature/components/inputs/StringInput/styles.module.scss';

type ItemInputProps<T> = FeatureInputProps<T>;

const ItemInput: FC<ItemInputProps<string>> = observer(
  ({ value, label, error, onChange, isLoading = false }) => {
    const handler = useCallback((event) => onChange(event.target.value), [onChange]);

    return (
      <Placeholder isLoading={isLoading} width="100%" height="48px">
        <TextField
          value={value}
          onChange={handler}
          variant="outlined"
          label={label}
          error={!!error}
          size="small"
          className={classNames(styles.input, 'feature-read__input feature-read__input_string')}
          fullWidth
        />
      </Placeholder>
    );
  }
);

export { ItemInput };
