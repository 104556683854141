import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { FaqFieldAnswer } from '~/pages/faq/components/FaqFieldAnswer';
import { FaqFieldQuestion } from '~/pages/faq/components/FaqFieldQuestion';
import { FaqFieldSelect } from '~/pages/faq/components/FaqFieldSelect';
import {
  ANSWER_MAX_LENGTH,
  ANSWER_MIN_LENGTH,
  FAQ_PAGE_NAMES,
  FAQ_ROLE_NAMES,
  QUESTION_MAX_LENGTH,
  QUESTION_MIN_LENGTH,
} from '~/pages/faq/constants';
import { minMax } from '~/utils/validators';
import { DEFAULT_STATUS_NAMES } from '~/utils/constants';
import EntityButton from '~/common/components/EntityButton';

export const FAQ_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'custom',
    component: FaqFieldQuestion,
    name: 'question',
    label: 'Вопрос',
    validator: minMax(
      QUESTION_MIN_LENGTH,
      QUESTION_MAX_LENGTH
    )(`Должно быть от ${QUESTION_MIN_LENGTH} до ${QUESTION_MAX_LENGTH} символов`),
    required: true,
  },
  {
    type: 'custom',
    component: FaqFieldAnswer,
    name: 'answer',
    label: 'Ответ',
    validator: minMax(
      ANSWER_MIN_LENGTH,
      ANSWER_MAX_LENGTH
    )(`Должно быть от ${ANSWER_MIN_LENGTH} до ${ANSWER_MAX_LENGTH} символов`),
    required: true,
  },
  {
    type: 'custom',
    component: FaqFieldSelect,
    name: 'page',
    label: 'Страница',
    options: FAQ_PAGE_NAMES,
    required: true,
    filterable: true,
  },
  {
    type: 'custom',
    component: FaqFieldSelect,
    name: 'role',
    label: 'Роль',
    options: FAQ_ROLE_NAMES,
    required: true,
    filterable: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: DEFAULT_STATUS_NAMES,
    hideInCreate: true,
    hideInEdit: true,
    required: true,
    sortable: true,
  },

  // block and unblock button
  {
    type: 'custom',
    name: 'blockButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="block" />,
  },
  // delete button
  {
    type: 'custom',
    name: 'deleteButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="delete" />,
  },
];
