import {
  Entity,
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityGetFunction,
  IEntityUpdateFunction,
} from 'icerockdev-admin-toolkit';
import { omit } from 'ramda';
import { uploadFile } from '~/utils/api';
import { axios } from '~/utils/axios';
import { API_URLS, DefaultStatus } from '~/utils/constants';

export const createItemsFn = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const image = await uploadFile({ file: data.image, token });
  const previewImg = await uploadFile({ file: data.previewImg, token });
  const model3D = data.model3D && (await uploadFile({ file: data.model3D, token }));
  const colorSolutions =
    data.colorSolutions &&
    (await Promise.all(
      data.colorSolutions.map(async (solution) => {
        const modelToken = await uploadFile({ file: solution.model3D ?? solution.file, token });
        const imageToken = await uploadFile({ file: solution.previewImg, token });
        return {
          colorSolutionId: solution.colorSolutionId ?? solution.id,
          model3D: modelToken,
          previewImg: imageToken,
        };
      })
    ));
  const examplesOfExecutions =
    data.examplesOfExecutions &&
    (await Promise.all(data.examplesOfExecutions.map(async (file) => uploadFile({ file, token }))));
  const examplesOfConstructions =
    data.examplesOfConstructions &&
    (await Promise.all(
      data.examplesOfConstructions.map(async (file) => uploadFile({ file, token }))
    ));

  const req = {
    ...data,
    name: data.name.trim(),
    image,
    previewImg,
    model3D,
    colorSolutions,
    examplesOfConstructions,
    examplesOfExecutions,
    status: DefaultStatus.Active,
  };

  const result = await axios.post(url, req, {
    headers: { authorization: token ?? '' },
  });

  return { data: result.data.data };
};

export const getActiveColorSolutions = async (entity: Entity) => {
  const response = await axios.get(API_URLS.colorSolution, {
    params: {
      status: DefaultStatus.Active,
    },
    headers: {
      authorization: entity.parent?.auth?.token ?? '',
    },
  });

  if (!response.data?.data) return {};

  return { ...response.data.data.reduce((acc, curr, i) => ({ ...acc, [i]: curr }), {}) };
};

export const getActiveFittingSolutions = async (entity: Entity) => {
  const response = await axios.get(API_URLS.fittingSolution, {
    params: {
      status: DefaultStatus.Active,
    },
    headers: {
      authorization: entity.parent?.auth?.token ?? '',
    },
  });

  if (!response.data?.data) return {};

  return { ...response.data.data.reduce((acc, curr, i) => ({ ...acc, [i]: curr }), {}) };
};

export const getItemsFn: IEntityGetFunction = async ({ url, token, id }) => {
  const result = await axios.get(`${url}/${id}`, {
    headers: {
      authorization: token ?? '',
    },
  });

  result.data.data.colorSolutions = result.data.data.colorSolutions.map((el) => el.colorSolutionId);

  return {
    data: result.data.data,
    error: '',
  };
};

export const updateItemsFn: IEntityUpdateFunction = async ({ url, token, data }) => {
  const image = await uploadFile({ file: data.image, token });
  const previewImg = await uploadFile({ file: data.previewImg, token });
  const model3D = data.model3D && (await uploadFile({ file: data.model3D, token }));
  const colorSolutions =
    data.colorSolutions &&
    (await Promise.all(
      data.colorSolutions.map(async (solution) => {
        const modelToken = await uploadFile({ file: solution.model3D ?? solution.file, token });
        const imageToken = await uploadFile({ file: solution.previewImg, token });
        return {
          colorSolutionId: solution.colorSolutionId ?? solution.id,
          model3D: modelToken,
          previewImg: imageToken,
        };
      })
    ));
  const examplesOfExecutions =
    data.examplesOfExecutions &&
    (await Promise.all(data.examplesOfExecutions.map(async (file) => uploadFile({ file, token }))));
  const examplesOfConstructions =
    data.examplesOfConstructions &&
    (await Promise.all(
      data.examplesOfConstructions.map(async (file) => uploadFile({ file, token }))
    ));

  const req = {
    name: data.name.trim(),
    image,
    previewImg,
    model3D,
    colorSolutions,
    fittingSolutions: data.fittingSolutions,
    examplesOfConstructions,
    examplesOfExecutions,
  };

  const normalizedData = omit(
    Object.keys(data).filter((el) => el.includes('Url')),
    data
  );

  const result = await axios.put(
    `${url}/${data.id}`,
    { ...normalizedData, ...req },
    {
      headers: { authorization: token ?? '' },
    }
  );

  return {
    data: result.data,
  };
};
