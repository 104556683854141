import React, { FC, useCallback } from 'react';
import { TableCell, TableSortLabel } from '@material-ui/core';
import { ENTITY_SORT_DIRS } from 'icerockdev-admin-toolkit';

type Props = {
  field: string;
  active?: boolean;
  direction?: typeof ENTITY_SORT_DIRS[keyof typeof ENTITY_SORT_DIRS];
  onSortChange: (field: string) => void;
};

const SortableHead: FC<Props> = ({ children, field, active, onSortChange, direction }) => {
  const onClick = useCallback(() => onSortChange(field), [field, onSortChange]);

  return (
    <TableCell>
      <TableSortLabel
        active={active}
        direction={active ? direction : ENTITY_SORT_DIRS.DESC}
        onClick={onClick}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

export default SortableHead;
