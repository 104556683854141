import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, Paper, withStyles, WithStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Entity, EntityField } from 'icerockdev-admin-toolkit';
import qs from 'query-string';
import { toJS } from 'mobx';
import styles from './styles';
import { CommonTabs } from '~/common/components/Tabs';
import { solutionModules, SolutionModulesName } from '~/pages/solution/constants';

type SolutionViewerProps = WithStyles<typeof styles> & {
  url: string;
  id?: string;
  errors: Record<string, string>;
  isEditing: boolean;
  isLoading: boolean;
  data: Record<string, any>;
  viewable: boolean;
  entity: Entity;

  setEditorData: (data: Record<string, any>) => void;
  getItem: (id: any) => void;
  cancelGetItem: () => void;
  onSave: () => void;
  onCancel: () => void;
  onResetFieldError: (field: string) => void;
  withToken?: (req: any, args: any) => any;
};

const SolutionViewer = withStyles(styles)(
  observer(
    ({
      classes,
      id,
      errors,
      onResetFieldError,
      isLoading,
      data,
      setEditorData,
      getItem,
      cancelGetItem,
      isEditing,
      entity,
    }: SolutionViewerProps) => {
      const isCreating = useMemo(() => typeof id === 'undefined', [id]);

      const { fields } = entity;

      const visibleFields = useMemo(
        () =>
          fields.filter(
            (field) =>
              (isEditing && !isCreating && !field.hideInEdit) ||
              (isCreating && !field.hideInCreate) ||
              (!isEditing && !isCreating && !field.hideInView)
          ),
        [fields, isEditing, isCreating]
      );

      const onFieldChange = useCallback(
        (f) => (value: any) => {
          if (errors[f]) {
            onResetFieldError(f);
          }

          setEditorData({ ...data, [f]: value });
        },
        [errors, setEditorData, data, onResetFieldError]
      );

      useEffect(() => {
        getItem(id);
        return () => cancelGetItem();
      }, [cancelGetItem, getItem, id]);

      const tabs = useMemo(
        () => solutionModules.map(({ title, url }) => ({ title, value: url })),
        []
      );

      const tabFromQuery = useMemo(() => {
        const query = qs.parse(window.location.search.slice(1, window.location.search.length)).tab;
        return query ? tabs.findIndex(({ value }) => value === query) : 0;
      }, [window.location.search]);

      const [activeTab, setActiveTab] = useState(tabFromQuery);

      const handleTabsChange = (_, value) => {
        setActiveTab(value);
      };

      useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(
          null,
          '',
          `${window.location.origin}${window.location.pathname}?tab=${tabs[activeTab].value}`
        );
      }, [activeTab]);

      const currentPage = useMemo(() => solutionModules[activeTab], [activeTab]);

      if (isLoading) {
        return (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        );
      }

      return (
        <div className={classes.wrap}>
          <Paper>
            <CommonTabs tabs={tabs} activeTab={activeTab} onChange={handleTabsChange} />
            <div
              className={classes.grid}
              style={{
                display: [SolutionModulesName.characteristics].includes(currentPage.title)
                  ? 'block'
                  : 'grid',
                gridTemplateColumns: [SolutionModulesName.model].includes(currentPage.title)
                  ? 'repeat(auto-fit, minmax(500px, 1fr))'
                  : 'repeat(auto-fill, minmax(500px, 1fr))',
              }}
            >
              {currentPage.fields.map((moduleField) => {
                const field = visibleFields.find(({ name }) => name === moduleField);
                const style = toJS(field?.options?.viewerStyle ?? {}) as React.CSSProperties;
                return (
                  field && (
                    <div className={classes.field} style={style} key={field.name}>
                      {!isEditing && (
                        <div className="label">
                          {field.label || field.name}
                          {isEditing && field.required && <span>{` *`}</span>}
                        </div>
                      )}
                      <div className="field">
                        <EntityField
                          name={field.name}
                          data={data}
                          entity={entity}
                          fields={fields}
                          isEditing={isEditing}
                          handler={onFieldChange(field.name)}
                        />
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </Paper>
        </div>
      );
    }
  )
);

export { SolutionViewer };
