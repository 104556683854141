// SolutionBreadcrumbs

import React, { useMemo, ReactElement } from 'react';
import { Grid, Breadcrumbs, Link, Typography, WithStyles, withStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styles from './styles';

type IProps = WithStyles<typeof styles> & {
  id?: string;
  name: string;
  parentName: string;
  parentId: string;
  url: string;
  buildParentUrl: (id: string) => string;
  isEditing?: boolean;
  isCreating?: boolean;
  fields: IEntityField[];
  data: Record<string, any>;
  buttons?: ReactElement;
  viewable: boolean;
  editable: boolean;
};

const SolutionBreadcrumbs = withStyles(styles)(
  observer(
    ({
      name,
      parentName,
      parentId,
      url,
      buildParentUrl,
      isEditing,
      isCreating,
      classes,
      id,
      fields,
      data,
      buttons,
      viewable,
    }: IProps) => {
      const { t } = useTranslation();
      const title = useMemo(() => {
        const field = fields.find((f) => f.title);
        return data && field && field.name ? data[field.name] : id;
      }, [data, fields, id]);

      return (
        <div className={classes.breadcrumbs}>
          <Grid container alignItems="center">
            <Grid style={{ flex: 1 }}>
              <Breadcrumbs aria-label="breadcrumb">
                {parentName && (
                  <Link color="inherit" to={buildParentUrl('')} component={RouterLink}>
                    {parentName}
                  </Link>
                )}

                {parentId && (
                  <Link color="inherit" to={buildParentUrl(parentId)} component={RouterLink}>
                    {parentId}
                  </Link>
                )}

                {name && <Typography color="textPrimary">{name}</Typography>}

                {isEditing && !isCreating && !!title && (
                  <Link color="inherit" to={viewable ? `${url}/${id}` : url} component={RouterLink}>
                    {title}
                  </Link>
                )}

                {!isEditing && !isCreating && (
                  <Typography color="textPrimary">
                    <Helmet>
                      <title>{title}</title>
                    </Helmet>
                    {title}
                  </Typography>
                )}

                {!!isEditing && !isCreating && (
                  <Typography color="textPrimary">
                    <Helmet>
                      <title>{`${t('Edit')}: ${title ?? ''}`}</title>
                    </Helmet>
                    {t('Edit')}
                  </Typography>
                )}

                {!isEditing && !!isCreating && (
                  <Typography color="textPrimary">
                    <Helmet>
                      <title>{`${t('Create')}: ${name ?? ''}`}</title>
                    </Helmet>
                    {t('Create')}
                  </Typography>
                )}
              </Breadcrumbs>
            </Grid>

            {buttons}
          </Grid>
        </div>
      );
    }
  )
);

export { SolutionBreadcrumbs };
