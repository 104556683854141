import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityUpdateFunction,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';
import { DefaultStatus } from '~/utils/constants';
import { uploadFile } from '~/utils/api';

export const createItemsFn = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const imageKey = await uploadFile({ file: data.image, token });

  const req = { ...data, name: data.name.trim(), image: imageKey, status: DefaultStatus.Active };

  const result = await axios.post(url, req, {
    headers: { authorization: token ?? '' },
  });

  return { data: result.data.data };
};

export const updateItemsFn: IEntityUpdateFunction = async ({ url, token, data }) => {
  const imageKey = data.image ? await uploadFile({ file: data.image, token }) : '';

  const request = {
    name: data.name.trim(),
    image: imageKey || data.image || data.imageKey,
    status: data.status,
  };

  const result = await axios.put(`${url}/${data.id}`, request, {
    headers: { authorization: token ?? '' },
  });

  return {
    data: result.data,
  };
};
